<template>
  <div id='news_info'>
    <Header :currentIndex="4" />
    <h1 class="info_title">News</h1>
    <div class="line"></div>
    <div v-if="dataInfo" class="info_content">
      <div class="info_content_title">{{dataInfo.title}}</div>
      <div class="info_content_time">{{dataInfo.create_datatime}}</div>
      <div class="info_line"></div>
      <div v-html="dataInfo.content" class="info_text"></div>
      <div class="Back">
        <div v-if="prevId !== 0" @click="toOther('prev')">Prev</div>
        <div v-else class="hidden">Prev</div>
        <div @click="toOther('back')">Back</div>
        <div v-if="nextId !== 0" @click="toOther('next')">Next</div>
        <div v-else class="hidden">Prev</div>
      </div>
    </div>
    <div v-else class="no_data"></div>
    <div class="bg_left"><img src="@assets/image/bg_left.png" alt=""></div>
    <div class="bg_right"><img src="@assets/image/bg_right.png" alt=""></div>
    <Footer :currentIndex="4"/>
  </div>
</template>

<script>
import Header from '@cpt/Header'
import Footer from '@cpt/Footer'
import {getNewsInfo} from '@api'

export default {
  data() {
    return {
      dataInfo: null,
      prevId: 0,
      nextId: 0
    }
  },
  components: {
    Header,
    Footer
  },
  created() {
    const {id} = this.$route.params
    this.getData(id)
  },
  methods:{
    getData(id) {
      getNewsInfo({id}).then(res => {
        const {prev_id, next_id} = res.data
        this.dataInfo = res.data
        this.prevId = prev_id
        this.nextId = next_id
      })
    },
    toOther(val) { // 返回新闻列表
      const {prevId, nextId} = this
      if(val === 'prev') this.$router.push({name: 'NewsInfo', params: {id: prevId}})
      if(val === 'back') this.$router.push({name: 'News'})
      if(val === 'next') this.$router.push({name: 'NewsInfo', params: {id: nextId}})
    }
  },
  watch: {
    $route(to, from) {
      const {id} = to.params
      this.getData(id)
    }
  }
}
</script>

<style lang='less' scoped>
#news_info {
  position: relative;
  overflow: hidden;
  .info_title {
    width: 1200px;
    margin: 178px auto 0;
    font-size: 49px;
    font-weight: bold;
    animation: move 1s;
  }
  @keyframes move {
    0% {
      transform: translateX(-500px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  .line {
    position: absolute;
    top: 305px;
    right: 0;
    width: 60%;
    height: 1px;
    background: #e5e5e5;
    z-index: -1;
  }
  .info_content {
    width: 1200px;
    margin: 64px auto 72px;
    .info_content_title {
      font-size: 54px;
      font-weight: bold;
    }
    .info_content_time {
      margin-top: 10px;
      color: #999;
      font-size: 23px;
      font-weight: bold;
    }
    .info_line {
      width: 800px;
      height: 1px;
      margin-top: 16px;
      background: #E6DBDA;
    }
    .info_text {
      margin-top: 36px;
      font-size: 14px;
    }
    .Back {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 1200px;
      margin: 72px auto;
      font-size: 14px;
      div {
        width: 150px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
      }
      div:hover {
        color: #2299E1;
      }
      .hidden {
        visibility: hidden;
      }
    }
  }
  .bg_left {
    position: absolute;
    top: 90px;
    left: 0;
    z-index: -1;
  }
  .bg_right {
    position: absolute;
    top: 875px;
    right: 0;
    z-index: -1;
  }
  .no_data {
    width: 100%;
    height: 143px;
  }
}
</style>